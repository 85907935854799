/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


html {
  background-color: #2f2f2f;
}

body {
  font-family: 'Poppins', Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

header {
  background-image: url('../public/re.png');
  padding: 8px 20px;
  position: relative;
  text-align: center;
}

header img {
  top: 10px;
  height: 96px;
}

header h1 {
  margin: 0;
  font-size: 2.5rem;
}

.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.hero {
  text-align: center;
  padding: 24px 20px;
  background-color: #2f2f2f;
}

.hero h2 {
  color: white;
  font-size: 2.2rem;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1.2rem;
  color: #ffd304;
}

.hero button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.hero button:hover {
  background-color: #45a049;
}

.features {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}

.feature-item {
  width: 25%;
  padding: 20px;
  background: linear-gradient(145deg, #ffffff, #ededed);
  box-shadow: -15px -12px 0px #d1d1d1, -5px -5px 9px;
  border-radius: 15px;
  text-align: center;
  margin: 12px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature-item:hover {
  transform: translateY(-10px);
  box-shadow: 10px 10px 20px #d1d1d1, -10px -10px 20px #ffffff;
}

.feature-item h3 {
  font-size: 1.5rem;
  color: #38b6ff;
  margin-bottom: 15px;
}

.feature-item p {
  color: #666;
  font-size: 1rem;
}

.testimonials {
  margin-top: 60px;
  text-align: center;
}

.testimonial-item {
  margin-bottom: 40px;
}

.testimonial-item p {
  font-size: 1rem;
  font-style: italic;
  color: #555;
}

.testimonial-item span {
  font-weight: bold;
  color: #4caf50;
}

.cta {
  background-color: #269de2;
  color: white;
  text-align: center;
  padding: 40px 20px;
  margin-top: 60px;
}

.cta h2 {
  margin-bottom: 20px;
  font-size: 2rem;
}

.cta button {
  background-color: white;
  color: #269de2;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: 2px solid #fff;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.cta button:hover {
  background-color: #269de2;
  color: white;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  margin-top: 20px;
}

footer p {
  margin: 0;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .features {
    flex-direction: column;
    align-items: center;
  }

  .feature-item {
    width: 80%;
    margin-bottom: 20px;
  }
}

.email-extraction-section {
  background-image: url('../public/re.png');
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.section-title {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

.section-intro {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 1rem;
}

.section-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
}

.benefits {
  margin-bottom: 2rem;
}

.benefits-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.benefits-list {
  list-style: none;
  padding: 0;
}

.benefit-item {
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.call-to-action {
  font-size: 1.2rem;
  color: #007bff;
  font-weight: bold;
}

th{
  border: solid 2px rgb(1, 111, 175);
  border-bottom: solid 2px rgb(1, 111, 175) !important;
}

td{
  border: solid 2px rgb(1, 111, 175);
  border-bottom: solid 2px rgb(1, 111, 175) !important;
}

table{
  border: solid 2px rgb(1, 111, 175);
}